.map-locations-card-distance {
  @apply tw-flex tw-text-lightGrey;

  svg {
    width: 20px;
    height: 20px;
  }
}

.map-location-card {
  border: 1px solid #00747300;

  &:hover {
    box-shadow: theme('boxShadow.cardHover');
  }

  &.active {
    border: 1px solid theme('colors.green.DEFAULT');
  }
}
