.header {
  @apply tw-flex
    tw-items-center
    tw-justify-between
    tw-text-green
    tw-font-bold;
}

.header-content-wrapper {
  @apply tw-flex
    tw-items-center
    tw-justify-between
    tw-py-3 lg:tw-py-4;
}

.overlay-element {
  @apply tw-fixed
    tw-bg-black
    tw-opacity-0
    tw-z-30
    tw-top-16
    md:tw-top-0
    tw-bottom-0
    tw-right-0
    tw-left-0
    tw-animate-fadeup75
    md:tw-animate-none;
}

.header-logo-content {
  @apply tw-flex tw-items-center tw-flex-1;

  & p {
    @apply tw-hidden tw-ml-6 sm:tw-inline;
  }
}

.header-logo-full {
  @apply tw-block;
}

.header-tagline {
  @apply tw-font-regular;
}

.header-list {
  @apply tw-flex;
}

.header-list li {
  @apply tw-shrink-0;
}

.header-list a {
  @apply tw-flex
    tw-justify-center
    tw-items-center
    tw-rounded-button
    tw-border-tertiary-active
    tw-border-opacity-0
    tw-border
    tw-h-full
    tw-px-4
    hover:tw-border-opacity-100
    active:tw-border-tertiary-dark;
}

.header-list .main-item {
  @apply lg:tw-hidden;
}

.header-list li:not(:first-of-type) {
  @apply tw-h-10 tw-ml-4;
}

.header-content {
  @apply tw-flex;
}

.header-content nav {
  @apply tw-items-center tw-hidden lg:tw-flex lg:tw-mr-8;
}

.header-content > * {
  @apply tw-mx-4;
}

.header-menu {
  @apply tw-flex
    tw-relative
    tw-justify-center
    tw-shrink-0
    tw-items-center
    tw-w-10
    tw-mx-0
    md:tw-ml-4
    tw-rounded-button
    tw-border-tertiary-active
    tw-border-opacity-0
    tw-border
    tw-z-40
    hover:tw-border-opacity-100
    active:tw-border-tertiary-dark;
}

.header-menu-line {
  @apply tw-absolute tw-w-[18px] tw-left-[10px] tw-bg-green tw-h-[1px] tw-transition-transform tw-ease-in-out;

  &:first-child {
    @apply tw-top-4;
  }

  &:nth-child(2) {
    @apply tw-top-[21px];
  }
}

.header-menu.active .header-menu-line {
  &:first-child {
    @apply tw-rotate-45 tw-top-[20px];
  }

  &:nth-child(2) {
    @apply -tw-rotate-45 tw-top-[20px];
  }
}

.header-flyout-menu {
  @apply tw-bg-white
    tw-fixed
    tw-bottom-0
    tw-left-full
    tw-max-w-sm
    tw-w-[80%]
    tw-pt-10
    tw-px-8
    tw-h-[calc(100%-4rem)]
    lg:tw-h-[calc(100%-4.5rem)]
    tw-ease-in-out
    tw-transition-transform
    tw-duration-300
    tw-translate-x-0
    tw-z-40;
}

.header-flyout-menu.active {
  @apply -tw-translate-x-full
    tw-shadow-menu;
}

.header-flyout-menu-content {
  transition: visibility 0s linear 500ms;
  @apply tw-invisible;
}

.header-flyout-menu.active .header-flyout-menu-content {
  transition: visibility 0s linear;
  @apply tw-visible;
}

.header-flyout-menu .header-list {
  @apply tw-flex-col;
}

.header-flyout-menu .header-list li {
  @apply tw-m-0 tw-mb-2 tw-h-10 tw-uppercase;
}

.header-flyout-menu .header-list a {
  @apply tw-justify-start tw-normal-case;
}
