.form-geocoder {
  .mapboxgl-ctrl-geocoder {
    width: 100%;
    font-size: 18px;
    line-height: 1.5;
    max-width: none;
    box-shadow: none;

    .suggestions {
      border-radius: 8px;
      padding: 12px;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
      font-family: "bebas-neue-pro-expanded", sans-serif;

      li > a {
        padding: 8px 16px;
        color: #111111;
      }

      .active > a {
        background-color: #BBE2B7;
        border-radius: 3px;
      }
    }
    .mapboxgl-ctrl-geocoder--button {
      top: 17px;
      right: 23px
    }
  }

  .mapboxgl-ctrl-geocoder--input {
    padding: 16px 56px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    font-family: "bebas-neue-pro-expanded", sans-serif;
    color: #111111;

    &:focus {
      outline: 1px solid #007473;
    }
  }

  .mapboxgl-ctrl-geocoder--icon-search {
    width: 24px;
    height: 24px;
    top: 18px;
    left: 21px;
  }
}
