.accordion {
  &.more {
    .icon-start svg {
      @apply tw-rotate-45;
    }
  }

  
  &-more-items {
    @apply tw-hidden;

    .more & {
      @apply tw-block;
    }
  }

  &-more {
    .more & {
      @apply tw-hidden;
    }
  }

  &-less {
    @apply tw-hidden;

    .more & {
      @apply tw-block;
    }
  }
}
