.front-page-search-wrapper {
  @apply
    tw-w-full
    tw-flex
    tw-mb-6
    tw-mt-6
    tw-max-w-[50rem]
}

.front-page-geowrapper {
  @apply tw-w-full;

  & .geocoder {
    width: 100%
  }
}

.hidden-map {
  @apply tw-absolute tw-hidden tw-pointer-events-none tw-w-0 tw-h-0 -tw-z-30;
}
