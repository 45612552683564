// Mapbox styles ---------------------------- CSS<3 ----------------------------
.mapboxgl-ctrl-group {
  box-shadow: none !important;
  background: none !important;
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl {
  margin: 1rem 1rem 0 0 !important;
}

.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl {
  margin: 0 1rem 1rem 0 !important;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  margin: 0 !important;
}

.mapboxgl-ctrl-group button {
  width: 2.5rem !important;
  height: 2.5rem !important;
  border-radius: 0.375rem !important;
  background-color: theme('colors.white.DEFAULT') !important;
  box-shadow: theme('boxShadow.menu');
}

.mapboxgl-ctrl-group button:hover {
  box-shadow: theme('boxShadow.cardHover');
}

.mapboxgl-ctrl-group button:active {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0)
}

.mapboxgl-ctrl-zoom-in {
  margin-bottom: 0.25rem !important;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGxpbmUgeDE9IjYiIHkxPSIxNi41IiB4Mj0iMjYiIHkyPSIxNi41IiBzdHJva2U9ImJsYWNrIi8+Cjwvc3ZnPgo=') !important;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI4IDE2TDQgMTYiIHN0cm9rZT0iYmxhY2siLz4KPHBhdGggZD0iTTE2IDI4TDE2IDQiIHN0cm9rZT0iYmxhY2siLz4KPC9zdmc+Cg==') !important;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-fullscreen .mapboxgl-ctrl-icon {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjEyNSAxMy44NzVMNS4yNSAxOC43NSIgc3Ryb2tlPSJibGFjayIvPgo8cGF0aCBkPSJNMTguNzUgNS4yNUwxMy44NzUgMTAuMTI1IiBzdHJva2U9ImJsYWNrIi8+CjxwYXRoIGQ9Ik00Ljg3NSAxNC4yNVYxOS4xMjVIOS43NSIgc3Ryb2tlPSJibGFjayIvPgo8cGF0aCBkPSJNMTkuMTI1IDkuNzVMMTkuMTI1IDQuODc1TDE0LjI1IDQuODc1IiBzdHJva2U9ImJsYWNrIi8+Cjwvc3ZnPgo=') !important;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-shrink .mapboxgl-ctrl-icon {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQuMTI1IDE5LjVMOSAxNC42MjUiIHN0cm9rZT0iYmxhY2siLz4KPHBhdGggZD0iTTQuMTI1IDMuNzVMOSA4LjYyNSIgc3Ryb2tlPSJibGFjayIvPgo8cGF0aCBkPSJNMTUgOC42MjVMMTkuODc1IDMuNzUiIHN0cm9rZT0iYmxhY2siLz4KPHBhdGggZD0iTTE1IDE0LjYyNUwxOS44NzUgMTkuNSIgc3Ryb2tlPSJibGFjayIvPgo8cGF0aCBkPSJNOS4zNzUgMTkuMTI1TDkuMzc1IDE0LjI1TDQuNSAxNC4yNSIgc3Ryb2tlPSJibGFjayIvPgo8cGF0aCBkPSJNNC41IDlMOS4zNzUgOUw5LjM3NSA0LjEyNSIgc3Ryb2tlPSJibGFjayIvPgo8cGF0aCBkPSJNMTQuNjI1IDQuMTI1TDE0LjYyNSA5TDE5LjUgOSIgc3Ryb2tlPSJibGFjayIvPgo8cGF0aCBkPSJNMTkuNSAxNC4yNUwxNC42MjUgMTQuMjVMMTQuNjI1IDE5LjEyNSIgc3Ryb2tlPSJibGFjayIvPgo8L3N2Zz4K') !important;
}

// Markers ---------------------------------------------------------------------
.marker {
  --transition: 150ms ease-out;

  width: 30px;
  height: 30px;
  border-radius: 0.375rem;
  background-color: rgba(0, 116, 115, 0);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0);
  display: grid;
  place-items: center;
  transition: var(--transition);

  &.hidden {
    display: none !important;
  }

  &.opacity-0 {
    opacity: 0 !important;
  }
  &.opacity-100 {
    opacity: 1 !important;
  }

  &.twins {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;

    svg {
      flex-shrink: 0;
    }

    svg:not(:first-child) {
      margin-left: -10px;

      .map-icon-background {
        fill: var(--icon-color-2, var(--icon-color, theme('colors.green.DEFAULT')));
      }
    }
  }
}

.marker.active {
  background-color: var(--icon-color-1, var(--icon-color, theme('colors.green.DEFAULT')));
  width: 40px;
  height: 40px;
  transition: var(--transition);
  transition-property: width, height;

  &.twins {
    width: 60px;
    background: linear-gradient(to right, var(--icon-color-1, var(--icon-color, theme('colors.green.DEFAULT'))), var(--icon-color-2, var(--icon-color, theme('colors.green.DEFAULT'))));;

    svg:not(:first-child) {
      .map-icon-background {
        fill: #ffffff;
      }
    }
  }
}

.marker:hover:not(.static) {
  cursor: pointer;
}

.marker svg {
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.25))
}

.marker:not(.static):hover svg {
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.5))
}

.marker:not(.static):active svg {
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0));
}

.map-icon-background {
  fill: var(--icon-color-1, var(--icon-color, theme('colors.green.DEFAULT')));
  transition-delay: 100ms;
  transition: var(--transition);
}

.map-icon-letter {
  fill: theme('colors.white.DEFAULT');
  transition-delay: 100ms;
  transition: var(--transition);
}

.marker.active .map-icon-letter {
  fill: var(--icon-color-1, var(--icon-color, theme('colors.green.DEFAULT')));
}

.marker.active svg {
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0))
}

.marker.active {
  box-shadow: theme('boxShadow.menu');
}

.marker.active .map-icon-background {
  fill: theme('colors.white.DEFAULT');
}

// Amenities markers -----------------------------------------------------------
.marker-amenity {
  color: theme('colors.black.DEFAULT');
  background-color: theme('colors.white.DEFAULT');
  border-radius: 100%;
  width: 40px;
  height: 40px;
  box-shadow: theme('boxShadow.menu');
  display: grid;
  place-items: center;
  transition: color background-color 300ms ease-out;
  transition-delay: 100ms;

  & svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  &.active {
    color: theme('colors.white.DEFAULT');
    background-color: theme('colors.green.DEFAULT');
    transition: color background-color 300ms ease-out;
    transition-delay: 100ms;
  }
}

// Popup -----------------------------------------------------------------------
.mapboxgl-popup-tip {
  opacity: 0;
}

.mapboxgl-popup-content {
  font: 14px/21px "bebas-neue-pro-expanded", sans-serif !important;
  padding: 12px !important;
  box-shadow: theme('boxShadow.menu') !important;
  border-radius: 0.375rem !important;
}

.mapboxgl-popup-content:hover {
  box-shadow: theme('boxShadow.cardHover') !important;
}

.mapboxgl-popup-inner {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.mapboxgl-popup-inner:hover {
  color: theme('colors.green.DEFAULT');
}

.mapboxgl-popup-inner svg {
  display: inline;
  margin-right: 10px;
}

.mapboxgl-popup-inner picture {
  height: 111px;
  width: 176px;
  margin-bottom: 1rem;
}

.mapboxgl-popup-inner-wrapper.has-twin {
  .mapboxgl-popup-inner picture {
    display: none;
  }
}

.mapboxgl-popup-inner img {
  border-radius: theme('borderRadius.link');
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mapboxgl-popup-inner p {
  display: flex;
  align-items: center;
}

@media screen and (min-width: 767px) {
  .mapboxgl-popup-inner picture {
    display: block !important ;
    aspect-ratio: 1 / 1;
    height: 100px;
    margin-right: 1rem;
    margin-bottom: 0;
    width: auto;
  }

  .mapboxgl-popup-inner {
    font-size: 1rem;
    flex-direction: row;
  }
}
