* {
  box-sizing: border-box;
}

html,
body {
  font-size: 16px;
  -webkit-text-size-adjust: 100%;
  height: 100%;
}

html {
  scroll-padding-top: 6rem;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'bebas-neue-pro-expanded', sans-serif;
  display: flex;
  flex-direction: column;
  @apply tw-antialiased;
}

#content {
  flex-grow: 1;
}

body.no-scroll {
  overflow: hidden !important;
  height: 100% !important;
  position: relative;
}

pre {
  line-height: 1.2em;
  background: #eee;
  border: 1px solid black;
  color: red;
  padding: 20px 20px;
  margin: 20px;
  text-align: justify;
}

body.no-scroll {
  overflow: hidden;
}

.container {
  @apply tw-w-full tw-max-w-7xl tw-mx-auto tw-px-5;
}

.container-narrow {
  @apply tw-w-full tw-max-w-4xl tw-mx-auto tw-px-5;
}
.container-wide {
  @apply tw-w-full tw-mx-auto tw-max-w-screen-xxl;
}

.content-block {
  @apply tw-my-14 md:tw-my-22;

  &.tw-bg-purple,
  &.tw-bg-cream,
  &.tw-bg-yellow,
  &.tw-bg-peach,
  &.tw-bg-blue,
  &.tw-bg-grey,
  &.tw-bg-green,
  &.tw-bg-black,
  &.tw-bg-lightGreen {
    @apply tw-py-14 md:tw-py-22 tw-my-0;
  }
}

.ingress {
  color: currentColor;
  & ol,
    & ul {
      @apply tw-ml-7 tw-mb-6;
    }
  
    & ol {
      @apply tw-list-decimal;
    }
  
    & ul {
      @apply tw-list-disc;
    }
}

.page-header-button {
  @apply block-button;
  @apply tw-inline-block tw-mt-6;
}

.skip-to-content-link {
  position: absolute;
  left: 4px;
  top: 4px;
  transform: translateY(calc(-100% - 4px));
  transition: 150ms all;
  z-index: 99;
  opacity: 0;
}

.skip-to-content-link:focus {
  transform: translateY(0);
  opacity: 1;
}

.text-line-clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

// Single article
body.article-layout {
  .content-block {
    &:first-child {
      @apply tw-mt-6 md:tw-mt-8;
    }
  }
}

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.5 4L4.01472 12.4853' stroke='currentColor'/%3E%3Cpath d='M12.5 12.5L4.01472 4.01472' stroke='%23111111'/%3E%3C/svg%3E%0A");
  cursor: pointer;
}

.article-swiper,
.location-swiper {
  &.swiper-free-mode {
    :root & {
      .swiper-wrapper {
        scroll-snap-type: none;
        box-sizing: border-box;

        @apply tw-pb-2 tw-pl-5 lg:tw-pl-0;

        &:after {
          content: '';

          @apply tw-pr-5;
        }
      }
    }
  }
}

.article-card-size {
  @apply tw-max-w-sm;

  @media screen and (max-width: 768px) {
    width: calc(100% - 2.5rem);
  }
}

.password-container{
  position: relative;
}
.password-svg{
  position: absolute;
  right: 8px;
  top: 50%;
  cursor: pointer;
}
.fs-1-rem{
  font-size:1rem;
}
.password-svg.show path {
  d: path("m644-428-58-58q9-47-27-88t-93-32l-58-58q17-8 34.5-12t37.5-4q75 0 127.5 52.5T660-500q0 20-4 37.5T644-428Zm128 126-58-56q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-62-62q41-17 84-25.5t90-8.5q151 0 269 83.5T920-500q-23 59-60.5 109.5T772-302Zm20 246L624-222q-35 11-70.5 16.5T480-200q-151 0-269-83.5T40-500q21-53 53-98.5t73-81.5L56-792l56-56 736 736-56 56ZM222-624q-29 26-53 57t-41 67q50 101 143.5 160.5T480-280q20 0 39-2.5t39-5.5l-36-38q-11 3-21 4.5t-21 1.5q-75 0-127.5-52.5T300-500q0-11 1.5-21t4.5-21l-84-82Zm319 93Zm-151 75Z");
}
.max-w-modal{
  max-width:100%;
}