.specification-header {
  @apply tw-flex
    tw-flex-wrap
    tw-bg-main-primary
    tw-text-base-white
    tw-rounded-t-md
    tw-pt-6
    tw-px-3;

  & div {
    & p {
      @apply tw-text-bodyLarge-m md:tw-text-body;
    }

    & span {
      @apply tw-uppercase tw-text-bodyXs tw-mb-1;
    }
  }
}

.specifications-row {
  @apply tw-w-[50%] md:tw-w-[25%] tw-px-3 tw-pb-6;

  &.specifications-subrow {
    @apply tw-w-full md:tw-w-[25%] tw-pb-1 md:tw-pb-6;
  }
}

.specification-step {
  @apply tw-bg-secondary-cream
    tw-flex
    tw-flex-wrap
    tw-py-6
    md:tw-pb-0
    tw-px-3
    tw-border-b
    tw-border-neutral-light;

  &.faded {
    @apply tw-bg-secondary-fadedCream;

    & p {
      @apply tw-text-neutral-muted;
    }
  }

  & p {
    @apply tw-font-bold;

    & span {
      @apply tw-inline-block;
    }
  }
}

.specifications-table-section{
  .section-heading-container{
    @apply tw-bg-main-primary;
  }
}
