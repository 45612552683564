.employee-card {
  @apply tw-flex
    tw-flex-col
    tw-bg-base-white
    tw-rounded-md
    tw-shadow-menu;

  &.employee-card-wide {
    @apply md:tw-flex-row md:tw-max-w-[570px];

    & picture,
    img {
      @apply md:tw-rounded-t-none md:tw-rounded-l-md;
    }

    & picture {
      @apply md:tw-w-auto md:tw-h-[250px];
    }

    & .card-text {
      @apply md:tw-flex md:tw-flex-col md:tw-justify-center;

      & p:first-of-type {
        @apply md:tw-inline;
      }

      & h3 {
        @apply md:tw-m-0;
      }
    }
  }

  & picture,
  img {
    @apply tw-rounded-t-md;
  }

  & picture {
    aspect-ratio: 16 / 9;

  }

  & img {
    @apply tw-object-cover tw-h-full tw-w-full;
  }

  & .card-text {
    @apply tw-p-6;
  }

  & p.card-link {
    @apply tw-underline tw-underline-offset-4 tw-leading-6;

    & a {
      @apply tw-decoration-green;
    }
  }

  & p.card-link:not(:last-child) {
    @apply tw-mb-1;
  }

  &.employee-card-text {
    @apply tw-flex-row tw-shadow-none tw-max-w-none;

    & .card-text {
      @apply tw-p-0;

      & p {
        @apply tw-text-bodySmall;
      }

      & p:first-of-type {
        @apply tw-hidden;
      }

      & h3 {
        @apply tw-text-heading6 tw-mb-4;
      }
    }

    & picture,
    img {
      @apply tw-rounded-md;
    }

    & picture {
      @apply tw-mr-4 tw-h-[86px] tw-w-[86px] tw-pb-0;
    }
  }
}
