// tool top
:root{
  body {
    --green: rgb(0, 116, 115);
    --cream: #f8f4eb;
    --toggle-on-background: var(--green);
    --main3: var(--cream);
    --main8: #c7dede;
    --register-interest-background: var(--green);
    --share-copy-offer-background: var(--green);
    --share-copy-offer-color: white;
    --card-subheader-font-size: 0.625rem;
    --card-subheader-color: var(--main6);
    --make-offer-background: var(--green);
  }
}

.__react_component_tooltip {
  border: 0;

  & .css-m0hjvo.tooltip-card {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);

    & .title.main-title {
      font-size: 1rem;
      font-weight: 600;
    }

    & .properties .group {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      & .property {
        display: flex;
        justify-content: space-between;
        gap: 2rem;


        & .value.comingForSale,
        & .value.sold,
        & .value.reserved {
          border-radius: 3rem !important;
          font-size: 12px !important;
          letter-spacing: 0.05em !important;
          margin: 0 !important;
          color: black !important;
          font-weight: 600 !important;
        }

        & .value.sold {
          @apply tw-bg-peach;
        }

        & .value.comingForSale {
          @apply tw-bg-blue;
        }
      }
    }
  }
}


// modal form
.ReactModalPortal {

  .D_8V4WNEOrDAaha_d6nw ._cL5WIvaogB3tWNqBNSw {
    text-transform: uppercase;
  }


  .css-1ipqbfu {
    padding: 1rem;
    height: 100%;
  }

  .css-13ijn4y {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .badge,
  .badge.sold,
  .badge.comingForSale,
  .badge.reserved,
  .value.comingForSale,
  .value.sold,
  .value.reserved {
    border-radius: 3rem !important;
    font-size: 12px !important;
    letter-spacing: 0.05em !important;
    margin: 0 !important;
    color: black !important;
    font-weight: 600 !important;
  }

  .css-4k418u .title .badge.sold,
  .css-nyrgd .title .badge.sold {
    @apply tw-bg-peach;
  }

  .css-4k418u .properties .group .property .value.comingForSale,
  .css-nyrgd .properties .group .property .value.comingForSale {
    @apply tw-bg-blue;
  }

  @media screen and (min-width: 768px) {
    .css-13ijn4y>div {
      height: calc(100% - 6.5rem);
    }
  }

}

.project-picker {
  @apply tw-overflow-auto tw-mb-10 md:tw-mb-20;
  overflow: visible;

  .D_8V4WNEOrDAaha_d6nw .vPWutxDHwUAmTkqiK2LM {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.5rem !important;
  }

  // sold toggle header
  .css-nf3b3y {
    max-width: 80rem;
    padding: 0 1.25rem 1rem;
    margin: 0 auto;
    gap: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    // add title
    &:before {
      content: 'Velg din seksjon her';
      display: inline-flex;
      flex: auto;
      white-space: nowrap;
      @apply tw-text-heading4 tw-w-[100%] sm:tw-w-auto md:tw-text-heading3 tw-font-bold tw-text-green;
    }

    .mD8r3sXoUTEKgPbw5xQL {
      background: none;
    }

    // toggle buttons
    .bLDn6UC0ItDl2azQ1A_0 {
      border: none;

      & li:first-child {
        margin-right: 4px;
      }

      li {
        width: auto;
        border-radius: 5px;
        padding: 0.5rem;
        color: rgb(0, 116, 115);
        border: 1px solid rgb(199 222 222);

        span {
          color: rgb(0, 116, 115);
          font-size: 1rem;
        }

        & svg * {
          stroke: rgb(0 116 115);
        }

        &:hover {
          @apply tw-bg-cream;
        }

      }

      li.tg3M4j19x8FIeJI8Kzgt {
        background: theme("colors.fadedGreen.DEFAULT");
      }
    }
  }

  .css-1988yzx {
    display: none;
  }

  // visual wrapper
  .css-2mcn1z {
    padding: 0 !important;

    >.css-8atqhb {
      overflow: hidden;
      max-height: 800px;
      max-width: 80rem;
      margin: 0 auto;

      @media screen and (min-width: 768px) {
        padding: 0 1.25rem;
      }

      >.css-16839bs {
        max-height: 800px;
        border-radius: 0.375rem;
        overflow: hidden;
        overflow-y: auto;
      }
    }
  }

  .css-1q4csb6>div:first-child {
    @apply tw-bg-cream;
  }

  .uOdLWGcBPny9le30JZih .gkzAEY62gcZZmWoyoOyJ {
    display: flex !important;
    flex-direction: column;
    gap: 0.25rem !important;
  }

  .p91JZxSnjlB0Ns2AIYuX {
    display: flex;
    justify-content: space-between;
    align-items: center;

    ._cL5WIvaogB3tWNqBNSw {
      margin: 0;
      font-size: 1rem;
    }
  }

  .css-17wzk6 tbody tr.disabled {
    background: none;
    color: black;
  }

  .badge,
  .badge.sold,
  .badge.comingForSale,
  .badge.reserved,
  .value.comingForSale,
  .value.sold,
  .value.reserved {
    border-radius: 3rem !important;
    font-size: 12px !important;
    letter-spacing: 0.05em !important;
    margin: 0 !important;
    color: black !important;
    font-weight: 600 !important;
    padding: 7px 1rem;
  }

  .css-pvo0c4.disabled,
  .css-51ralc.disabled,
  .css-1to3caj.disabled,
  .css-8o16gt.disabled,
  .css-4k418u.disabled,
  .css-wqa3d3.disabled,
  .css-gxy5e9.disabled,
  .css-eq8ao4.disabled,
  .css-1bj6gjs.disabled,
  .css-1dhr7da.disabled,
  .css-1j6aamh.disabled .css-wqa3d3,
  .css-11wvtx7,
  .css-1hrwp0o,
  .css-1j6aamh,
  .css-swl6p9,
  .slick-slide>div>div {
    background-color: white;
    color: black;
    min-height: auto;
    border-color: transparent;

  }

  .css-1yq67xq .slick-slide div {
    width: auto;
  }

  .css-8o16gt.disabled,
  .css-1l6uol1.disabled {
    transition: 200ms ease;
    border: 1px solid rgb(186 0 0 / var(--tw-bg-opacity)) !important;
  }

  .css-51ralc.disabled,
  .css-1bj6gjs.disabled {
    transition: 200ms ease;
    border: 1px solid rgb(186 218 251 / var(--tw-bg-opacity)) !important;
  }

  .badge.sold,
  .value.sold {
    @apply tw-bg-peach #{!important};
  }

  .badge.comingForSale,
  .value.comingForSale {
    @apply tw-bg-blue #{!important};
  }

  .css-hanekf {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
    overflow: hidden;
  }

  .css-14v0l9e.disabled,
  .css-12blpot.disabled,
  .css-1ukhcgi.disabled,
  .css-1l6uol1.disabled,
  .css-17lqyz7.disabled,
  .css-136ujqz.disabled,
  .css-ac8hrd.disabled,
  .css-nyrgd.disabled,
  .css-swl6p9.disabled,
  .css-1hrwp0o.disabled,
  polygon[style*="rgba(231, 88, 59, 0.3)"] {
    background-color: #ffffff;
    border-color: transparent;
    color: #000000;
    //opacity: 0.5;
    //pointer-events: none;
  }

  .css-17wzk6 tbody tr:hover td {
    background: #e7e0cb;
  }

  .css-asild5,
  .css-2ld6dh,
  .css-xjiwy1,
  .css-34fkwo,
  .css-140kkgt,
  .css-17wzk6,
  .css-e2yjur,
  .css-1w4dk2b,
  .css-plso9l,
  .css-44vqkb,
  .css-1mkzcou,
  .css-s45rmk,
  .css-17dflt8,
  .css-1ed4tnt,
  .css-1stk37e,
  .css-xd111x,
  .css-nbct3n,
  .css-awdvxj,
  .css-1fvdbxd,
  .css-1ofpizv,
  .css-awdvxj,
  .css-6yigck {
    @apply tw-bg-cream;
  }


  .css-10x5o0f {}

  .css-1wgwg9s {
    @apply tw-items-center;

    .titleContainer .prevTitle {
      @apply tw-text-body;
    }
  }

  .css-140kkgt {
    min-height: 400px;
  }

  .css-10x5o0f,
  .css-1xmwdor {
    .title {
      @apply tw-text-heading6 tw-font-bold;
    }

    .properties .group .property {
      .label {
        @apply tw-text-bodyXs;
      }

      .value {
        @apply tw-text-body;
      }
    }
  }

  .css-1xmwdor {
    @apply tw-border-transparent tw-shadow-md;
  }




  .css-lhmq3r {
    .css-b83igd.checked {
      background: theme("colors.green.DEFAULT");
    }
  }

  //list view
  //wrapper
  .SgWvr17OcKt_xzRerUBJ {
    @apply tw-bg-cream;

    //header
    & thead tr th div {
      border: none;
      display: flex;
      align-items: center;
    }

    & th:first-child div {
      margin-left: 1.5rem;
    }

    & th div {
      padding: 1.5rem 0;
    }

    & th:first-child div {
      margin-left: 1.5rem;
    }

    & tbody tr:not(:last-child) td {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    & tbody tr.disabled {
      background: none;
      color: black;
    }

    & tbody tr:hover td {
      background: #ebe9e1;

    }

    & thead tr {
      background: #ebe9e1;
      background-color: var(--green);
      color: white;
    }
  }
}


.css-127cgrc,
.copy .css-1s4yvq3 {
  @apply tw-text-base-white tw-bg-green hover:tw-bg-main-hover focus:tw-bg-main-hover active:tw-bg-main-active #{!important};
}

.css-7pl6ha {
  background: none !important;
  @apply tw-text-main-primary tw-border tw-border-tertiary-active hover:tw-border-tertiary-dark focus:tw-border-tertiary-dark active:tw-border-main-primary #{!important};
}

.css-7dvia,
.css-1stk37e,
.css-plso9l,
.css-awdvxj,
.css-vgsfyt {
  background-color: rgb(248 244 235) !important;
}