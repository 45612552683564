.sticky-menu {

  &-links {
    @apply tw-appearance-none lg:tw-overflow-x-visible;
  }

  .sticky-menu-links a {
    @apply tw-text-base-black tw-no-underline tw-font-regular tw-whitespace-nowrap tw-px-5 tw-py-4;

    &.current {
      @apply tw-underline tw-font-bold;
    }
  }
}