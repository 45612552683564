.locations-mapbox-container {
  display: unset;
}

.map-search-map-outer {
  width: 100%;
  height: 20rem;
  transition: height 300ms ease-out;
  overflow: hidden;
  position: relative;
  margin-top: 2rem;
}

.map-search-map-inner {
  width: 100%;
  height: 75vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.map-search-map-outer.expanded {
  transition: height 300ms ease-in;
}

.button-wrapper {
  width: 100%;
  position: absolute;
  top: 5.625rem;
  left: 0;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1rem;
}

.button-content-placer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
}

.button-tray {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-wrap: wrap;
}

.toggle-settings {
  width: 2.5rem;
  height: 2.5rem;
  display: grid;
  place-items: center;
  border-radius: theme('borderRadius.button');

  &:hover {
    background-color: theme('colors.fadedGreen.DEFAULT');
  }
}

.filter-spacer {
  height: 0;
  transition: height 300ms ease-out;
}

.button-tray-sizes {
  display: flex;
  align-items: center;

  > *:not(:first-child) {
    margin-left: 0.5rem;
  }
}

.geocoder-wrapper {
  margin: 0 auto;
  max-width: 81.25rem;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  position: relative;
  gap: 1rem;
  flex-wrap: wrap;
}

@media screen and (max-width: 400px) {
  .button-content-placer .toggleLabel {
    font-size: 0.875rem;
  }
}

@media screen and (min-width: 770px) {
  .button-tray {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .button-wrapper {
    position: static;
  }

  .filter-spacer {
    display: none;
  }

  .toggle-settings {
    display: none;
  }
}
