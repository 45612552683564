.block-link {
    @apply tw-font-bold tw-px-[1px] tw-border tw-border-[transparent] tw-h-6 tw-relative tw-inline-flex  tw-items-center tw-text-body tw-text-main-primary tw-rounded-link tw-underline tw-underline-offset-5 focus:tw-no-underline focus:tw-border-main-primary hover:tw-decoration-1 tw-decoration-1;

    svg {
        @apply tw-inline tw-w-4 tw-h-4;
    }
    &.icon-start {
        svg {
            @apply tw-mr-2;
        }
    }

    &.icon-end {
        svg {
            @apply tw-ml-2;
        }
    }
}
