body.location-print {
  // Hide
  .header,
  .footer-main {
    @apply tw-hidden;
  }

  // Specifications
  .specifications-row {
    @apply tw-w-[25%] tw-px-3 tw-pb-6;

    &.specifications-subrow {
      @apply tw-w-[25%] tw-pb-6;
    }
  }

  // Employees
  .module-employees {
    // @apply tw-my-4;

    // &-list {
    //   @apply tw-grid tw-grid-cols-3;
    // }

    .employee-card {
      @apply tw-max-w-none;

      a {
        &:after {
          @apply tw-hidden;
        }
      }
    }
  }

  // Hero description header
  #description-heading {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @apply tw-text-white tw-text-center;
    }
  }
}
