.rich-text {
  @apply tw-text-bodyLarge;

  a:not(.primary-button):not(.tertiary-button):not(.page-header-button) {
    @apply tw-underline;
  }

  p:not(:last-child) {
    @apply tw-mb-4;
  }

  p {
    @apply tw-text-body md:tw-text-bodyLarge tw-mt-2 md:tw-mt-6;
  }

  h2 {
    @apply tw-text-green tw-text-heading3 md:tw-text-heading2 tw-mb-3 tw-mt-6 tw-font-bold tw-mt-0 tw-mb-2 md:tw-mb-6;
  }

  h3 {
    @apply tw-text-green tw-text-heading4 md:tw-text-heading3 tw-mb-3 tw-mt-6 tw-font-bold;
  }

  h5 {
    @apply tw-text-green tw-text-heading5 tw-mb-3 tw-mt-6 tw-font-bold;
  }

  ol,
  ul {
    @apply tw-ml-7 tw-mb-6;
  }

  ol {
    @apply tw-list-decimal;
  }

  ul {
    @apply tw-list-disc;
  }

  &.white {
    @apply tw-text-black;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    a:not(.page-header-button) {
      @apply tw-text-green;
    }
  }

  &.green {
    @apply tw-text-white;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    a:not(.page-header-button) {
      @apply tw-text-white;
    }
  }

  &.lightGreen {
    @apply tw-text-black;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    a:not(.page-header-button) {
      @apply tw-text-green;
    }
  }

  &.yellow {
    @apply tw-text-black;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    a:not(.page-header-button) {
      @apply tw-text-black;
    }
  }

  &.peach {
    @apply tw-text-black;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    a:not(.page-header-button) {
      @apply tw-text-black;
    }
  }

  &.blue {
    @apply tw-text-black;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    a:not(.page-header-button) {
      @apply tw-text-black;
    }
  }

  &.grey {
    @apply tw-text-white;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    a:not(.page-header-button) {
      @apply tw-text-white;
    }
  }

  &.purple {
    @apply tw-text-black;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    a:not(.page-header-button) {
      @apply tw-text-black;
    }
  }

  &.cream {
    @apply tw-text-black;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    a:not(.page-header-button) {
      @apply tw-text-green;
    }
  }

  figure {
    @apply tw-mb-4;
  }

  figcaption {
    @apply tw-text-body tw-mt-3;
  }

  iframe {
    width: 100%;
  }

  figure > iframe {
    @supports (aspect-ratio: 16 / 9) {
      width: 100% !important;
      height: auto !important;
      aspect-ratio: 16 / 9;
    }
  }
}
