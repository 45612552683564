.rich-text-blockquote {
  @apply tw-relative tw-py-12 tw-pl-10 tw-mb-6;

  & blockquote {
    @apply tw-text-ingress-m md:tw-text-ingress
  }
  & figcaption {
    @apply tw-text-bodySmall
  }
}

.rich-text-blockquote::before {
  content: '';
  background-color: theme('colors.green.DEFAULT');
  width: 2px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.rich-text-blockquote blockquote::before {
  content: '';
  width: 2px;
  height: 40px;
  background-color: theme('colors.white.DEFAULT');
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.rich-text-blockquote::after {
  content: '”';
  color: theme('colors.green.DEFAULT');
  font-size: theme('fontSize.heading1');
  height: 100%;
  display: grid;
  place-content: center;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate3d(-0.5rem, 12px, 0);
}
