.collapsible {
  &-panel {
    @apply tw-hidden tw-pr-12;

    .collapsed & {
      @apply tw-block;
    }
  }

  &-logo {
    @apply tw-rotate-180;

    .collapsed & {
      @apply tw-rotate-0;
    }
  }
}
