.module-contact-form {
  @apply tw-p-0 tw-overflow-hidden;

  &.location-page {
    @apply tw-overflow-visible
  }

  .contact-form {
    @apply -tw-mx-0;
  }
}

.contact-form {
  @apply md:tw-rounded-md;

  &-main {
    .submitted & {
      @apply tw-hidden;
    }
  }

  &-thanks {
    @apply tw-hidden;

    .submitted & {
      @apply tw-block;
    }
  }
}
