.custom-checkbox {
  @apply tw-absolute tw-appearance-none;
}

.custom-checkbox:checked + .custom-checkmark {

  @apply tw-border-main-primary;

  svg {
    @apply  tw-block tw-bg-opacity-100 tw-visible tw-mr-2;
  }
}

.custom-checkbox:focus + .custom-checkmark {
  @apply tw-border-main-primary;
}

.custom-checkmark {
  @apply tw-py-2 tw-px-3 tw-rounded-md tw-border tw-border-main-primary10 tw-relative tw-overflow-hidden tw-flex tw-items-center tw-duration-150 tw-cursor-pointer hover:tw-border-main-primary50 tw-shrink-0;

  svg {
    @apply tw-hidden;
  }
}
