.map-card-outer {
  @apply
    tw-w-full
    tw-flex
    tw-flex-col
    md:tw-flex-row-reverse
    md:tw-min-h-[284px];
}

.map-card-map-wrapper {
  @apply tw-w-full tw-h-[62vw] md:tw-h-auto md:tw-w-[60%];
}

.map-card-map-wrapper canvas {
  @apply tw-rounded-r-md md:tw-rounded-r-md tw-h-full;
}