.rich-text-image {
  & img {
    @apply tw-rounded-md tw-w-full;
  }

  & figcaption {
    @apply tw-text-bodySmall
      tw-w-full
      md:tw-w-[72%]
      tw-mt-3;
  }
}
