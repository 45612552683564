.footer-main {
  @apply tw-bg-green
    tw-text-white
    tw-flex
    tw-justify-center
    tw-pt-8
    md:tw-pt-12;
}

.footer-link-outer {
  @apply md:tw-flex md:tw-justify-between md:tw-mb-8;
}

.footer-content-lower {
  &:before {
    content: "";
  }
  @apply tw-flex
    tw-flex-wrap
    tw-pt-4
    tw-relative
    tw-pb-4
    md:tw-pt-0
    md:tw-flex-row-reverse
    md:tw-justify-between
    before:tw-absolute
    before:tw-h-[1px]
    before:tw-bg-white
    before:tw-opacity-50
    before:-tw-left-5
    before:-tw-right-5
    before:tw-top-0
    before:tw-w-screen
    md:before:tw-hidden;
}

.footer-content-lower > div {
  @apply tw-flex tw-flex-wrap;

  &:first-child {
    @apply tw-flex
      tw-w-full
      tw-mb-5
      md:tw-w-auto
      md:tw-m-0
      md:tw-ml-2;
  }

  &:nth-child(2) {
    @apply tw-flex
      md:tw-flex-row-reverse;
  }

  .footer-lower-text-link {
    @apply tw-mr-6;
  }
}

.footer-links {
  @apply md:tw-flex md:tw-justify-end md:tw-flex-grow md:tw-ml-6;
}

.footer-links > li {
  @apply tw-mb-6
    last:tw-mb-8
   md:last:tw-mb-6
   md:tw-max-w-[208px]
   md:tw-w-full
   md:tw-ml-6;
}

.footer-links > ul li.label {
  @apply tw-text-bodyXs tw-text-base-white tw-pb-2 tw-uppercase last:tw-pb-0;
}

.footer-links a {
  @apply tw-text-body tw-text-base-white tw-normal-case;
}
