.block-button {
    @apply tw-font-bold tw-h-10 tw-text-body tw-flex tw-shrink-0 tw-items-center tw-justify-center tw-px-3.5 tw-py-2 tw-rounded-button tw-cursor-pointer tw-no-underline hover:tw-no-underline tw-w-auto;

    &.link-button{
        @apply tw-inline-flex;
    }

    &:disabled {
        @apply tw-opacity-50 tw-pointer-events-none;
    }
}

.primary-button {
    @apply tw-text-base-white tw-bg-main-primary hover:tw-bg-main-hover focus:tw-bg-main-hover active:tw-bg-main-active;
}
.secondary-button {
    @apply tw-text-base-black tw-bg-cta-primary hover:tw-bg-cta-hover focus:tw-bg-cta-hover active:tw-bg-cta-active;
}
.tertiary-button {
    @apply tw-text-base-black tw-bg-base-white hover:tw-bg-tertiary-hover focus:tw-bg-tertiary-hover active:tw-bg-tertiary-active;
}

.outline-button {
    @apply tw-text-main-primary tw-border tw-border-tertiary-active hover:tw-border-tertiary-dark focus:tw-border-tertiary-dark active:tw-border-main-primary;
}

.icon-button {
    svg {
        @apply tw-h-5 tw-w-5 tw-mr-1 ;
    }
}
