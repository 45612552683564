.timeline {
  &-state {
    @apply tw-relative;
  }

  &--wip {
    @apply tw-bg-green tw-text-white;

    .timeline-state-circle {
      @apply tw-bg-cream;
    }
  }

  &--completed {
    @apply tw-opacity-60;
  }

  &--default {
    @apply tw-bg-white;
  }

  &-state-circle {
    @apply tw-w-2 tw-h-2 tw-bg-neutral-grey tw-rounded-full tw-z-10;
  }

  &-state-outer-circle {
    @apply tw-w-5 tw-h-5 tw-bg-white tw-rounded-full tw-absolute tw-top-1/2 tw-left-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2 tw-opacity-20;
  }

  &-more {
    @apply tw-hidden;

    .more & {
      @apply tw-block;
    }
  }
}
