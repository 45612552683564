.two-column-text__text-wrapper {
  p:not(:last-child) {
    margin-bottom: 1rem;
  }

  a {
    text-decoration: underline;
    text-underline-offset: 0.25rem;
  }
}
