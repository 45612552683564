.geocoder {
  width: calc(100% - 4rem);
  flex-grow: 1;
  position: relative;
  min-height: 4rem;
  background-color: theme('colors.white.DEFAULT');
  border-radius: 0.5rem;
  box-shadow: theme('dropShadow.menu');

  @media screen and (min-width: 770px) {
    width: 100%;
  }

  .mapboxgl-ctrl-geocoder {
    border-radius: 0.5rem;
    box-shadow: none;
    max-width: 56rem;
    width: auto;
  }

  .input-button-tray {
    position: absolute;
    z-index: 10;
    right: 0.75rem;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .geocoder-button {
    &:first-child {
      display: flex;
      color: theme('colors.green.DEFAULT');
      align-items: center;
      border: 1px solid #00525100;
      border-radius: theme('borderRadius.button');
      padding: 0.5rem 0.875rem 0.5rem 0.6875rem;

      span {
        text-decoration: underline;
        text-underline-offset: 0.25rem;
        margin-left: 0.375rem;
      }

      svg {
        width: 1rem;
        height: 1rem;
      }

      &:hover:not(:disabled) {
        color: theme('colors.green.hover');
        border-color: theme('colors.green.hover');
      }

      &:disabled {
        color: theme('colors.grey.DEFAULT');
      }
    }
  }

  .mapboxgl-ctrl-geocoder--icon-search {
    left: 0.6875rem;
    height: 1rem;
    width: 1rem;
    top: 1.4375rem;
  }

  .mapboxgl-ctrl-geocoder--button {
    height: 1.5rem;
    width: 1.5rem;
    top: 1.4375rem;
    right: 8.75rem;
    border-radius: theme('borderRadius.button');
    border: 1px solid #00525100;
  }

  .mapboxgl-ctrl button:not(:disabled):hover {
    background: none;
    color: theme('colors.green.hover');
    border-color: theme('colors.green.hover');
  }

  .mapboxgl-ctrl-geocoder--input {
    font-size: 1.125rem;
    line-height: 2.5rem;
    padding: 0.75rem 7.3125rem 0.75rem 2.25rem;
    height: auto;
    font-family: "bebas-neue-pro-expanded", sans-serif;
    border-radius: 0.5rem;

    &:focus {
      box-shadow: theme('boxShadow.menu');
      outline: none;
    }
  }

  .mapboxgl-ctrl-geocoder .suggestions {
    background: theme('colors.white.DEFAULT');
    padding: 0.75rem;
    box-shadow: theme('dropShadow.menu');
    font-size: 1rem;
    font-family: "bebas-neue-pro-expanded", sans-serif;
    border-radius: 0.375rem;

    .active a {
      background-color: theme('colors.lightGreen.DEFAULT');
      border-radius: theme('borderRadius.link');

      &:hover {
        background-color: theme('colors.lightGreen.DEFAULT');
      }
    }

    a:hover {
      background-color: theme('colors.lightGreen.hover');
    }
  }
}

@media screen and (min-width: 770px) {
  .geocoder {
    min-height: 4.5rem;

    .input-button-tray {
      right: 2rem;
    }

    .mapboxgl-ctrl-geocoder--icon-search {
      left: 1.0625rem;
      height: 1.5rem;
      width: 1.5rem;
    }

    .mapboxgl-ctrl-geocoder--input {
      padding: 1rem 8.3125rem 1rem 3.5rem;
    }
  }
}
