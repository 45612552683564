.button-options {
  button:not(.active) {
    @apply tw-text-main-primary;
  }

  button.active {
    @apply tw-text-base-white;
    @apply tw-bg-main-primary;
    @apply tw-pointer-events-none;
  }
}