@import 'src/css/blocks/rich_text/__richText.scss';


@page {
  // Suggest page size to increase uniformity across browsers
  size: A4;
  margin: 10mm;
}

@page:first {
  margin: 10mm;
}

@page :footer {
  display: none;
}

@page :header {
  display: none;
}


@media print and (color) {
  // Suggest bg colors and images when printing
  // A lot of browsers strip these by default when printing
  * {
    -webkit-print-color-adjust: exact;
    -moz-print-color-adjust: exact;
    -ms-print-color-adjust: exact;
    print-color-adjust: exact;
    -webkit-filter:opacity(1);
  }

  // Show link urls
  // a::after {
  //   content: ' (' attr(href) ') ';

  //   @apply tw-text-bodyXs;
  // }
}

.rich-text-print {
  @extend .rich-text;

  p {
    @apply tw-text-bodyPrintSmall tw-mt-2 tw-mb-0;
    &:not(:last-child) {
      @apply tw-mb-0;
    }
  }
  ul {
    @apply tw-list-disc tw-text-bodyPrintSmall tw-mb-4;
  }
}

#print-footer {
  z-index: -1;
  display: none;
}

.page-count:before {
  counter-increment: page;
  content: counter(page);
  display: block;
}

.container-print {
  @apply tw-mx-auto;
  max-width: 210mm;
}

.gallery-wrapper {
  margin-left: auto;
  margin-right: auto;
}

.gallery-mid-wrapper {
  display: flex;
  justify-content: center;
  gap: 24px;
  margin: 24px 0 24px;
}

.gallery-captions {
  height: 24px;
}

.gallery-images-top {
  height: 280px;
  width: 100%;
  object-fit: cover;
  object-position: bottom;
}

.gallery-images-middle {
  height: 230px;
  width: 100%;
}

.gallery-images-bottom {
  height: 280px;
  width: 100%;
  object-fit: cover;
  object-position: bottom;
}

.siteplan-image {
  height: 420px;
  width: 100%;
  object-fit: contain;
  object-position: bottom;
}

.buildplan-image {
  height: 420px;
  width: 100%;
  object-fit: contain;
  object-position: bottom;
}

// Page specific print sheets
@import 'css/print/location';
