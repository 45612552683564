.toggleButton {
  @apply tw-relative tw-cursor-pointer tw-pr-10 tw-inline-flex tw-justify-start tw-items-center tw-min-h-[40px];
  &:hover {
    .toggleSlider {
      @apply tw-border-main-primary;
    }
    .toggleInput:checked + .toggleSlider {
      @apply tw-border-neutral-grey;
    }
  }
}

.toggleLabel {
  @apply tw-mr-2 tw-text-body tw-shrink-0 tw-leading-6;
}

.toggleInput {
  @apply tw-appearance-none;

  &:disabled {
    @apply tw-pointer-events-none;
  }
}
.toggleInput:disabled + .toggleSlider {
  @apply tw-opacity-50;
}

.toggleSlider {
  @apply tw-bg-neutral-grey tw-cursor-pointer tw-absolute tw-transition tw-right-0 tw-top-2 tw-w-10 tw-h-6 tw-rounded-3xl tw-overflow-hidden tw-border-2 tw-border-transparent;
}

.toggleSlider:after {
  content: '';
  @apply tw-bg-base-white tw-w-5 tw-h-5 tw-absolute  tw-left-0 tw-top-0 tw-transition tw-rounded-full;
}

.toggleInput:checked + .toggleSlider {
  @apply tw-bg-main-primary;
}

.toggleInput:focus + .toggleSlider {
  @apply tw-border-main-primary;
}
.toggleInput:checked:focus + .toggleSlider {
  @apply tw-border-neutral-grey;
}

.toggleInput:checked + .toggleSlider:after {
  @apply tw-translate-x-4;
}
