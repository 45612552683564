.simple-checkbox {
  @apply tw-absolute tw-appearance-none;
}

.simple-checkbox:checked + .simple-checkmark {
  @apply tw-border-main-primary tw-bg-main-primary;

  svg {
    @apply tw-block tw-bg-opacity-100 tw-visible tw-text-base-white;
  }
}
.simple-checkbox:hover + .simple-checkmark {
  @apply tw-border-main-primary;
}
.simple-checkbox:focus + .simple-checkmark {
  @apply tw-border-main-primary tw-border-2;
}

.simple-checkmark {
  @apply tw-mr-2 tw-rounded-sm tw-border tw-w-4 tw-h-4 tw-flex tw-items-center tw-justify-center tw-border-neutral-dark;

  svg {
    @apply tw-hidden;
  }
}
