.gallery-component {
  @apply tw-relative;

  .swiper-gallery {
    @apply tw-w-full md:tw-rounded-md tw-mb-1 md:tw-mb-2;
  }
  .swiper-gallery-lightbox {
    @apply tw-mb-2  tw-mx-auto md:tw-rounded-md;
  }

  .swiper-thumbnail .swiper-slide {
    @apply tw-opacity-60;
  }

  .swiper-thumbnail .swiper-slide-thumb-active {
    @apply tw-opacity-100;
  }

  .swiper-slide-main {
    @apply tw-relative tw-flex tw-items-center tw-justify-center tw-aspect-video tw-cursor-pointer;
  }
  .swiper-slide-main img,
  .swiper-slide-main iframe {
    @apply tw-object-cover tw-max-h-full tw-mx-auto md:tw-rounded-md tw-object-center tw-w-full tw-h-full;
  }

  .swiper-slide-thumbnail {
    @apply tw-cursor-pointer tw-opacity-60;
  }

  .swiper-slide-thumbnail img {
    @apply tw-object-cover tw-max-h-full tw-aspect-video  tw-w-full tw-object-center md:tw-rounded-md;
  }

  .swiper-slide-thumbnail.swiper-slide-thumb-active {
    @apply tw-opacity-100;
  }
  .swiper-slide-thumbnail.swiper-slide-thumb-active img {
    @apply tw-border-green tw-border-3 tw-overflow-hidden;
  }

  // lightbox gallery
  .gallery-lightbox {
    @apply tw-fixed tw-inset-0 tw-bg-white tw-z-50 tw-duration-300 tw-transition tw-overflow-auto tw-flex-col tw-hidden;

    .close-lightbox {
      @apply tw-w-14 tw-h-14 tw-p-5 tw-absolute tw-right-2 tw-top-2 md:tw-right-4 md:tw-top-4 tw-text-base-black tw-bg-base-white hover:tw-bg-tertiary-hover focus:tw-bg-tertiary-hover active:tw-bg-tertiary-active tw-z-20 tw-rounded-button;
    }
  }
  .gallery-lightbox-open {
    @apply tw-flex;
  }

  .gallery-lightbox iframe {
    @apply tw-object-contain tw-max-w-full tw-cursor-default tw-rounded-md tw-mx-auto tw-w-full tw-aspect-video tw-max-h-full;
  }

  .swiper-button-prev-lightbox,
  .swiper-button-next-lightbox {
    @apply tw-absolute tw-top-[calc(50%-40px)] -tw-translate-y-1/2 tw-z-10 tw-w-8 tw-h-8 tw-text-main-primary tw-rounded-full tw-border tw-border-main-primary  tw-border-main-primary10  hover:tw-border-main-primary50 focus:tw-border-main-primary disabled:tw-border-main-primary10 disabled:tw-text-main-primary10 disabled:tw-cursor-not-allowed tw-flex tw-items-center tw-justify-center;
  }
  .swiper-button-disabled {
    @apply tw-pointer-events-none;
  }

  .swiper-button-prev-lightbox {
    @apply tw-hidden md:tw-flex md:tw-left-4;
  }
  .swiper-button-next-lightbox {
    @apply tw-hidden md:tw-flex md:tw-right-4;
  }
}
