.filter-options {
  @apply tw-relative tw-opacity-0 tw-invisible  md:tw-opacity-100 md:tw-visible tw-h-0 md:tw-h-auto  md:tw-grow md:tw-shrink-0;

  &-visible {
    @apply tw-opacity-100 tw-visible tw-h-auto tw-duration-300 tw-ease-in;
  }
}

.toggle-button-selected {
  @apply tw-bg-main-active;
}
